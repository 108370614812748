<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: { Header, Form },

  computed: {
    ...mapState("indicators", ["indicator"]),
  },
  methods: {
    ...mapActions("indicators", ["ActionNew", "ActionSetIndicator"]),
    loadData(id) {
      console.log("ID", id);
      this.$router.push({ name: "indicators_edit", params: { id: id } });
    },
  },
  async created() {
    this.ActionNew();
  },
};
</script>

<style lang="scss">
.vue-step-wizard {
  width: 100%;
}
</style>
